import * as React from 'react';
import { connect } from 'react-redux';

import { ContractReduxState } from '../../../../store/reducer';
import {
  ContractFormData,
  ContractVariableData,
} from '../../../../common/ContractData';
import QuestionInputWrapper from './QuestionInputWrapper';
import QuestionComboInputWrapper from './QuestionComboInputWrapper';
import {
  ContractDefinitionQuestionEnriched,
  ContractDefinitionEnriched,
} from '../../../../common/ContractDefinitionEnriched';
import PlaceholderHelper from '../../../../common/PlaceholderHelper';
import { Collapse } from '@mui/material';

export interface OwnProps {
  questionId: string;
  questionDefinition: ContractDefinitionQuestionEnriched;
}

interface StateProps {
  contractDefinition: ContractDefinitionEnriched;
  contractFormData: ContractFormData;
  contractVariableData: ContractVariableData;
}

type Props = StateProps & OwnProps;

class Question extends React.Component<Props> {
  render(): JSX.Element {
    const questionDefinition = PlaceholderHelper.assembleQuestionTexts(
      this.props.questionDefinition,
      this.props.contractDefinition,
      this.props.contractFormData,
      this.props.contractVariableData,
    );

    if ( questionDefinition.combo === true ) {
      return (
        <div id={this.props.questionId}>
          <QuestionComboInputWrapper
            questionId={this.props.questionId}
            questionDefinition={questionDefinition}
          />
        </div>
      );
    }

    return (
      <div id={this.props.questionId}>
        <QuestionInputWrapper
          questionId={this.props.questionId}
          questionDefinition={questionDefinition}
        />
      </div>
    );
  }
}

const mapStateToProps = ( state: ContractReduxState ): StateProps => {
  return {
    contractDefinition: state.contractDefinition,
    contractFormData: state.contractFormData,
    contractVariableData: state.contractVariableData,
  };
};

export default connect( mapStateToProps )( Question );
