import React from 'react';
import {
  Card,
  CardActions,
  CardContent,
  Grid,
  IconButton,
  Typography,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DownloadIcon from '@mui/icons-material/Download';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import downloadContract from '../../common/downloadContract';
import DoneIcon from '@mui/icons-material/Done';
import { ContractPartner } from '../../common/ContractPartner';
import DateHelper from '../../common/DateHelper';

export default function ClosedContractCard( props: {
  contractDataId: number;
  contractPartner: ContractPartner;
  title: string;
  createdAt: string;
  updatedAt: string;
} ): JSX.Element {
  const { contractDataId, contractPartner, title, createdAt, updatedAt } =
    props;

  function handleDownload() {
    //downloadContract( contractId, contractFormData, 'contract' );
  }

  let details: string | JSX.Element = '';
  if ( contractPartner.data[ 0 ].__component === 'contact.natural-person' ) {
    details = (
      <>
        {contractPartner.data[ 0 ].firstname} {contractPartner.data[ 0 ].lastname}
        <br />
        {contractPartner.data[ 0 ].streetHouseNumber}
        <br />
        {contractPartner.data[ 0 ].zipCity}
        <br />
        &nbsp;
        <br />
        &nbsp;
      </>
    );
  } else if ( contractPartner.data[ 0 ].__component === 'contact.legal-entity' ) {
    details = (
      <>
        {contractPartner.data[ 0 ].company}
        <br />
        {contractPartner.data[ 0 ].streetHouseNumber}
        <br />
        {contractPartner.data[ 0 ].zipCity}
        <br />
        {contractPartner.data[ 0 ].representative}
        <br />
        {contractPartner.data[ 0 ].functionRepresentative}
      </>
    );
  }

  return (
    <Card>
      <CardContent>
        <Typography
          variant="h6"
          component="div"
          color="#2e7d32"
          overflow="hidden"
          textOverflow="ellipsis"
        >
          {title}
        </Typography>
        <hr />
        <Typography
          variant="body2"
          sx={{
            textOverflow: 'ellipsis',
            overflow: 'hidden',
          }}
        >
          {details}
        </Typography>
        <br />
        <Typography variant="subtitle2">
          <AddIcon fontSize="inherit" /> {DateHelper.simpleDate( createdAt )}
          <br />
          <DoneIcon fontSize="inherit" /> {DateHelper.simpleDate( updatedAt )}
        </Typography>
      </CardContent>
      <CardActions>
        <IconButton onClick={handleDownload}>
          <DownloadIcon />
        </IconButton>
      </CardActions>
    </Card>
  );
}
