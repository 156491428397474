import React, { useState, useEffect } from 'react';
import {
  Accordion,
  AccordionSummary,
  Button,
  Container,
  Divider,
  Grid,
  Paper,
  Typography,
} from '@mui/material';
import OpenContractCard from './openContractCard';
import NewContractCard from './newContractCard';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ClosedContractCard from './closedContractCard';
import { useNavigate } from 'react-router-dom';
import ApiHelper, { ContractData } from '../../common/ApiHelper';
import { ContractDefinition } from '../../common/ContractDefinition';
import DateHelper from '../../common/DateHelper';

export default function Dashboard() {
  const [ contractDefinitions, setContractDefinitions ] = useState<
    ContractDefinition[] | null
  >();
  const [ contracts, setContracts ] = useState<{
    closedContracts: { [title: string]: ContractData[] };
    openContracts: { [title: string]: ContractData[] };
  } | null>();

  const navigate = useNavigate();

  const fetchContracts = () => {
    ApiHelper.listContractDefinitions().then( ( response ) => {
      setContractDefinitions( response.data );
    } );

    ApiHelper.listContractDatas().then( ( response ) => {
      const closedContracts: { [title: string]: ContractData[] } = {};
      const openContracts: { [title: string]: ContractData[] } = {};
      response.data.forEach( ( contractData ) => {
        if ( contractData.attributes.closed ) {
          if (
            !closedContracts[
              contractData.attributes.contract_definition.data.attributes
                .titlePlural
            ]
          ) {
            closedContracts[
              contractData.attributes.contract_definition.data.attributes.titlePlural
            ] = [];
          }

          closedContracts[
            contractData.attributes.contract_definition.data.attributes
              .titlePlural
          ].push( contractData );
        } else {
          if (
            !openContracts[
              contractData.attributes.contract_definition.data.attributes
                .titlePlural
            ]
          ) {
            openContracts[
              contractData.attributes.contract_definition.data.attributes.titlePlural
            ] = [];
          }

          openContracts[
            contractData.attributes.contract_definition.data.attributes
              .titlePlural
          ].push( contractData );
        }
      } );
      setContracts( { closedContracts, openContracts } );
    } );
  };

  useEffect( () => {
    fetchContracts();
  }, [] );

  function handleDelete( contractDataId: number ): void {
    ApiHelper.deleteContractData( contractDataId ).then( () => {
      fetchContracts();
    } );
  }

  function handleClose( contractDataId: number ): void {
    ApiHelper.closeContractData( contractDataId ).then( () => {
      fetchContracts();
    } );
  }

  const availableContractDefinitions: JSX.Element[] = [];

  if ( contractDefinitions ) {
    contractDefinitions.forEach( ( availableContractDefinition ) => {
      availableContractDefinitions.push(
        <Grid item xs={12} sm={6} lg={3} key={availableContractDefinition.id}>
          <NewContractCard
            contractDefinitiontId={availableContractDefinition.id}
            title={availableContractDefinition.attributes.title}
            shortDescription={
              availableContractDefinition.attributes.shortDescription
            }
          />
        </Grid>,
      );
    } );
  }

  let openContractCounter = 0;
  const openContractsJSX: JSX.Element[] = [];

  if ( contracts ) {
    Object.entries( contracts.openContracts ).forEach( ( [ key, contracts ] ) => {
      openContractCounter += contracts.length;

      const contractCardsJSX: JSX.Element[] = contracts.map(
        ( contract, index ) => {
          return (
            <Grid item xs={12} sm={6} lg={3} key={index}>
              <OpenContractCard
                contractDataId={contract.id}
                contractPartner={
                  contract.attributes.second_party.data.attributes
                }
                title={contract.attributes.shortNote}
                createdAt={contract.attributes.createdAt}
                updatedAt={contract.attributes.updatedAt}
                handleClose={() => {
                  handleClose( contract.id );
                }}
                handleDelete={() => {
                  handleDelete( contract.id );
                }}
              />
            </Grid>
          );
        },
      );

      openContractsJSX.push(
        <Accordion key={key}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <Typography>
              {key} ({contracts.length})
            </Typography>
          </AccordionSummary>
          <Grid container spacing={3} p={3}>
            {contractCardsJSX}
          </Grid>
        </Accordion>,
      );
    } );
  }

  let closedContractCounter = 0;
  const closedContractsJSX: JSX.Element[] = [];

  if ( contracts ) {
    Object.entries( contracts.closedContracts ).forEach( ( [ key, contracts ] ) => {
      closedContractCounter += contracts.length;

      const contractCardsJSX: JSX.Element[] = contracts.map(
        ( contract, index ) => {
          return (
            <Grid item xs={12} sm={6} lg={3} key={index}>
              <ClosedContractCard
                contractDataId={contract.id}
                contractPartner={
                  contract.attributes.second_party.data.attributes
                }
                title={contract.attributes.shortNote}
                createdAt={contract.attributes.createdAt}
                updatedAt={contract.attributes.updatedAt}
              />
            </Grid>
          );
        },
      );

      closedContractsJSX.push(
        <Accordion key={key}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <Typography>
              {key} ({contracts.length})
            </Typography>
          </AccordionSummary>
          <Grid container spacing={3} p={3}>
            {contractCardsJSX}
          </Grid>
        </Accordion>,
      );
    } );
  }

  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
            <Typography variant="h4" component="div">
              Dashboard Vertragsgenerator CLARIUS.LEGAL
            </Typography>
            <Divider sx={{ mt: 2, mb: 1 }} />
            <p>Hallo Herr Demo,</p>
            <p>
              willkommen auf Ihrem Dashboard.{' '}
              {openContractCounter == 0 ||
                `Aktuell sind noch ${ openContractCounter } Verträge in Bearbeitung. `}
              Heute ist der {DateHelper.simpleDateNow()}.
            </p>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
            <Typography variant="h4" component="div">
              Neuen Vertrag erstellen
            </Typography>
            <Divider sx={{ mt: 2, mb: 3 }} />
            <Grid container spacing={3}>
              {availableContractDefinitions}
            </Grid>
          </Paper>
        </Grid>
        {openContractCounter === 0 ? (
          <></>
        ) : (
          <Grid item xs={12}>
            <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
              <Typography variant="h4" component="div">
                Verträge in Bearbeitung
              </Typography>
              <Divider sx={{ mt: 2, mb: 3 }} />
              {openContractsJSX}
            </Paper>
          </Grid>
        )}
        {closedContractCounter === 0 ? (
          <></>
        ) : (
          <Grid item xs={12}>
            <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
              <Typography variant="h4" component="div">
                Abgeschlossene Verträge {/*(letzte 30 Tage)*/}
              </Typography>
              <Divider sx={{ mt: 2, mb: 3 }} />
              {closedContractsJSX}
              {/*<Button
                sx={{ mt: 3 }}
                onClick={() => {
                  navigate( '/archive' );
                }}
              >
                Zum Archiv
              </Button>*/}
            </Paper>
          </Grid>
        )}
      </Grid>
    </Container>
  );
}
