import * as React from 'react';

import { ContractDefinitionAttachmentQuestion } from '../../../../../common/ContractDefinition';
import { ContractFormData } from '../../../../../common/ContractData';

export interface OwnProps {
  question: ContractDefinitionAttachmentQuestion;
  value: string | boolean;
  showValidationErrors: boolean;
  contractFormData: ContractFormData;
  onValueChange: ( answer: string ) => void;
}

class AttachmentInput extends React.Component<
  OwnProps,
  {
    timeoutId: number | null;
    value: string;
    showValidationErrors: boolean;
  }
> {
  constructor( props: OwnProps ) {
    super( props );

    this.state = {
      timeoutId: null,
      value: '0',
      showValidationErrors: false,
    };
  }

  onValueChange( event: React.ChangeEvent<HTMLInputElement> ): void {
    if ( this.state.timeoutId !== null ) {
      clearTimeout( this.state.timeoutId );
    }
    const value = event.target.value;

    const timeoutId = window.setTimeout( () => {
      this.props.onValueChange( this.state.value );
    }, 2000 );

    const newValue = this.sanitizeValue( value ).toString();

    this.setState( {
      ...this.state,
      timeoutId: timeoutId,
      value: newValue,
    } );
  }

  onBlur( event: React.ChangeEvent<HTMLInputElement> ): void {
    const value = event.target.value.trim();
    if ( this.state.timeoutId !== null ) {
      clearTimeout( this.state.timeoutId );
    }

    const newValue = this.sanitizeValue( value ).toString();

    this.setState( { ...this.state, value: newValue } );
    this.props.onValueChange( newValue );
    this.enableValidationErrors();
  }

  enableValidationErrors(): void {
    this.setState( { ...this.state, showValidationErrors: true } );
  }

  sanitizeValue( value: string | number ): number {
    if ( typeof value !== 'string' && typeof value !== 'number' ) {
      value = 0;
    }

    if ( typeof value === 'string' ) {
      value = Number.parseInt( value.replace( /\D/g, '' ) );

      if ( Number.isNaN( value ) ) {
        value = 0;
      }
    }

    return Math.abs( value );
  }

  render(): JSX.Element {
    const classes: string[] = [ 'clarius-gen-answer-block' ];

    if ( this.props.showValidationErrors || this.state.showValidationErrors ) {
      classes.push( 'clarius-gen-show-errors' );
    }

    return (
      <div className={classes.join( ' ' )}>
        <span>
          <label>Attachment - Not implemented!!</label>
        </span>
        <br />
      </div>
    );
  }
}

export default AttachmentInput;
