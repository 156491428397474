import * as React from 'react';
import { connect } from 'react-redux';

import { ContractReduxState } from '../../../../../store/reducer';
import { ContractDefinitionMultipleChoiceQuestion } from '../../../../../common/ContractDefinition';
import evaluateConditions from '../../../../../common/evaluateConditions';
import {
  ContractFormData,
  ContractVariableData,
} from '../../../../../common/ContractData';
import { FormGroup, FormControlLabel, Switch, Typography } from '@mui/material';

export interface OwnProps {
  question: ContractDefinitionMultipleChoiceQuestion;
  name: string;
  value: ( string | boolean )[];
  showValidationErrors: boolean;
  onValueChange: ( answer: string | null, add: boolean ) => void;
}

interface StateProps {
  contractFormData: ContractFormData;
  contractVariableData: ContractVariableData;
}

type Props = StateProps & OwnProps;

class MultipleChoiceInput extends React.Component<
  Props,
  {
    showValidationErrors: boolean;
  }
> {
  constructor( props: Props ) {
    super( props );
    this.state = { showValidationErrors: false };
  }

  onValueChange( event: React.ChangeEvent<HTMLInputElement> ): void {
    if ( event.target.checked ) {
      this.props.onValueChange( event.target.value, true );
    } else {
      this.props.onValueChange( event.target.value, false );
    }
  }

  enableValidationErrors(): void {
    this.setState( { ...this.state, showValidationErrors: true } );
  }

  render(): JSX.Element {
    if (
      evaluateConditions( this.props.question.conditions, {
        ...this.props.contractFormData,
        ...this.props.contractVariableData,
      } )
    ) {
      const checkboxes: JSX.Element[] = [];

      this.props.question.choices.forEach( ( choice ) => {
        checkboxes.push(
          <div key={choice.name}>
            <FormControlLabel
              control={
                <Switch
                  onChange={( e ): void => this.onValueChange( e )}
                  checked={this.props.value.includes( choice.name )}
                />
              }
              value={choice.name}
              label={choice.shortAnswer}
            />
            <Typography variant="body2">{choice.longAnswer}</Typography>
          </div>,
        );
      } );

      return <FormGroup>{checkboxes}</FormGroup>;
    }

    return <></>;
  }
}

const mapStateToProps = ( state: ContractReduxState ): StateProps => {
  return {
    contractFormData: state.contractFormData,
    contractVariableData: state.contractVariableData,
  };
};

export default connect( mapStateToProps )( MultipleChoiceInput );
