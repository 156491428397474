import * as localStorage from 'local-storage';

export default class LocalStorageHelper {
  public static set<T>( key: string, value: T ): boolean {
    return localStorage.set( `clarius-gen-${ key }`, value );
  }

  public static get<T>( key: string ): T {
    return localStorage.get( `clarius-gen-${ key }` );
  }

  public static remove( key: string ): void {
    return localStorage.remove( `clarius-gen-${ key }` );
  }

  public static clear(): void {
    return localStorage.clear();
  }
}
