import { Box, Container, Fab, Grid, Typography } from '@mui/material';
import * as React from 'react';
import ChevronLeft from '@mui/icons-material/ChevronLeft';
import ChevronRight from '@mui/icons-material/ChevronRight';

interface OwnProps {
  currentPage: number;
  adjustedCurrentPage: number;
  totalPages: number;
  adjustedTotalPages: number;
  pageSwitchCallback( toPage: number | string, replace?: boolean ): void;
}

class Paginator extends React.Component<OwnProps> {
  render(): JSX.Element {
    const prevButton: JSX.Element = (
      <Fab
        color="primary"
        aria-label="add"
        disabled={this.props.currentPage <= 0}
        onClick={(): void =>
          this.props.pageSwitchCallback( this.props.currentPage )
        }
      >
        <ChevronLeft />
      </Fab>
    );

    const nextButton: JSX.Element = (
      <Fab
        color="primary"
        aria-label="add"
        disabled={this.props.currentPage >= this.props.totalPages}
        onClick={(): void =>
          this.props.pageSwitchCallback( this.props.currentPage + 2 )
        }
      >
        <ChevronRight />
      </Fab>
    );

    return (
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Grid item xs={1} textAlign="left">
          {prevButton}
        </Grid>
        <Grid item xs textAlign="center">
          {this.props.adjustedCurrentPage + 1} /{' '}
          {this.props.adjustedTotalPages + 1}
        </Grid>
        <Grid item xs={1} textAlign="right">
          {nextButton}
        </Grid>
      </Grid>
    );
  }
}

export default Paginator;
