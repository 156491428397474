import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Divider,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListSubheader,
} from '@mui/material';
import DashboardIcon from '@mui/icons-material/Dashboard';
import ArchiveIcon from '@mui/icons-material/Archive';
import AssignmentIcon from '@mui/icons-material/Assignment';
import NotificationHelper from '../../common/NotificationHelper';
import Settings from '../../common/Settings';
import LocalStorageHelper from '../../common/LocalStorageHelper';

export default function MenuList(): JSX.Element {
  const navigate = useNavigate();
  const [ contractList, setContractList ] = useState<
    { filename: string; title: string }[]
  >( [] );
  const [ fetchStarted, setFetchStarted ] = useState( false );

  let listItems: JSX.Element[] = [];

  useEffect( () => {
    if ( !fetchStarted ) {
      if ( contractList.length > 0 ) {
        contractList.forEach( ( contract ) => {
          listItems.push(
            <ListItemButton
              key={contract.filename}
              onClick={() => navigate( `/contract/${ contract }` )}
            >
              <ListItemIcon>
                <AssignmentIcon />
              </ListItemIcon>
              <ListItemText
                primary={contract.title}
                sx={{
                  overflow: 'hidden',
                }}
              />
            </ListItemButton>,
          );
        } );
      } else {
        setFetchStarted( true );
        fetch( `${ Settings.apiUrl }/generator/listjsons`, {
          headers: {
            Authorization: 'Bearer ' + LocalStorageHelper.get( 'token' ),
          },
        } )
          .then( ( resolve ) => resolve.json() )
          .then( ( listjsonsData: { filename: string; title: string }[] ) => {
            setContractList( listjsonsData );
          } )
          .catch( ( error ) => {
            NotificationHelper.error(
              'Verträge konnten nicht geladen werden.',
              error,
            );
            listItems = [ <>Verträge konnten nicht geladen werden</> ];
          } );
      }
    }
  } );

  return (
    <List component="nav">
      <ListItemButton onClick={() => navigate( '/' )}>
        <ListItemIcon>
          <DashboardIcon />
        </ListItemIcon>
        <ListItemText primary="Dashboard" />
      </ListItemButton>
      {/*<ListItemButton onClick={() => navigate( '/archive' )}>
        <ListItemIcon>
          <ArchiveIcon />
        </ListItemIcon>
        <ListItemText primary="Archive" />
      </ListItemButton>*/}
      <Divider sx={{ my: 1 }} />
      {/*<ListSubheader component="div" inset>
        Verfügbare Verträge
      </ListSubheader>
      {listItems} */}
    </List>
  );
}
