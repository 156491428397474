import * as React from 'react';
import { connect } from 'react-redux';

import { ContractReduxState } from '../../../../store/reducer';
import {
  ContractDefinitionEnriched,
  ContractDefinitionQuestionEnriched,
} from '../../../../common/ContractDefinitionEnriched';
import ExplanationIcon from './InputElements/ExplanationIcon';
import QuestionInput from './QuestionInput';

export interface OwnProps {
  questionId: string;
  questionDefinition: ContractDefinitionQuestionEnriched;
}

interface StateProps {
  contractDefinition: ContractDefinitionEnriched | null;
  invalidQuestionData: string[];
}

type Props = StateProps & OwnProps;

class QuestionComboInputWrapper extends React.Component<Props, {}> {
  render(): JSX.Element {
    let explanationIcon: JSX.Element | null = null;
    if (
      this.props.questionDefinition.explanation !== undefined &&
      this.props.questionDefinition.explanation !== '' &&
      this.props.questionDefinition.explanation !== '\n' &&
      ( !Array.isArray( this.props.questionDefinition.explanation ) ||
        this.props.questionDefinition.explanation.length > 0 )
    ) {
      explanationIcon = (
        <ExplanationIcon
          explanationTitle={this.props.questionDefinition.questionText}
          explanation={this.props.questionDefinition.explanation as string}
        />
      );
    }

    return (
      <>
        <QuestionInput
          questionId={this.props.questionId}
          questionDefinition={this.props.questionDefinition}
          isInvalid={this.props.invalidQuestionData.includes(
            this.props.questionId,
          )}
        />
        {explanationIcon}
      </>
    );
  }
}

const mapStateToProps = ( state: ContractReduxState ): StateProps => {
  return {
    contractDefinition: state.contractDefinition,
    invalidQuestionData: state.invalidQuestionData,
  };
};

export default connect( mapStateToProps )( QuestionComboInputWrapper );
