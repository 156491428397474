import * as React from 'react';

import { ContractDefinitionSharesQuestion } from '../../../../../common/ContractDefinition';
import { ContractFormData } from '../../../../../common/ContractData';

export interface OwnProps {
  question: ContractDefinitionSharesQuestion;
  value: ( string | boolean )[];
  showValidationErrors: boolean;
  contractFormData: ContractFormData;
  onValueChange: ( answer: string[] ) => void;
}

class SharesInput extends React.Component<
  OwnProps,
  {
    timeoutId: number | null;
    value: string[];
    showValidationErrors: boolean;
    partyCount: number;
  }
> {
  constructor( props: OwnProps ) {
    super( props );

    const partyCount = Number.parseInt(
      `${ this.props.contractFormData.numberOfParties.answers?.[ 0 ] }`,
    );

    const valueStringified: string[] = this.props.value.map(
      ( value, indexNumber ) => {
        if ( indexNumber < partyCount ) {
          return `${ value }`;
        } else {
          return '';
        }
      },
    );

    this.state = {
      timeoutId: null,
      value: valueStringified,
      showValidationErrors: false,
      partyCount: partyCount,
    };
  }

  onValueChange(
    event: React.ChangeEvent<HTMLInputElement>,
    arrayId: number,
  ): void {
    if ( this.state.timeoutId !== null ) {
      clearTimeout( this.state.timeoutId );
    }
    const value = event.target.value;

    const timeoutId = window.setTimeout( () => {
      this.props.onValueChange( this.state.value );
    }, 2000 );

    const newValue = [ ...this.state.value ];
    newValue[ arrayId ] = this.sanitizeValue( value ).toString();

    this.setState( {
      ...this.state,
      timeoutId: timeoutId,
      value: newValue,
    } );
  }

  onBlur( event: React.ChangeEvent<HTMLInputElement>, arrayId: number ): void {
    const value = event.target.value.trim();
    if ( this.state.timeoutId !== null ) {
      clearTimeout( this.state.timeoutId );
    }

    const newValue = [ ...this.state.value ];
    newValue[ arrayId ] = this.sanitizeValue( value ).toString();

    this.setState( { ...this.state, value: newValue } );
    this.props.onValueChange( newValue );
    this.enableValidationErrors();
  }

  enableValidationErrors(): void {
    this.setState( { ...this.state, showValidationErrors: true } );
  }

  sanitizeValue( value: string | number ): number {
    if ( typeof value !== 'string' && typeof value !== 'number' ) {
      value = 0;
    }

    if ( typeof value === 'string' ) {
      value = Number.parseInt( value.replace( /\D/g, '' ) );

      if ( Number.isNaN( value ) ) {
        value = 0;
      }
    }

    return Math.abs( value );
  }

  render(): JSX.Element {
    const classes: string[] = [ 'clarius-gen-answer-block' ];

    if ( this.props.showValidationErrors || this.state.showValidationErrors ) {
      classes.push( 'clarius-gen-show-errors' );
    }

    const toDistribute: number = parseInt( this.props.question.data.shares );
    const undistributedShares: number = this.state.value.reduce(
      ( prev, curr ) => {
        const value = this.sanitizeValue( curr ?? '' );

        return prev - value;
      },
      toDistribute,
    );
    const undistributedSharesAbsolute =
      undistributedShares < 0 ? undistributedShares * -1 : undistributedShares;
    let stillToDistributeText = 'Verteilt werden muss noch';
    if ( undistributedShares < 0 ) {
      stillToDistributeText = 'Zuviel verteilt wurden';
    }
    const inputElements: JSX.Element[] = [];

    for ( let i = 1; i <= this.state.partyCount; i++ ) {
      inputElements.push(
        <label>
          Anteil von Vertragspartei {i}
          <input
            type="text"
            value={this.sanitizeValue( this.state.value[ i - 1 ] ?? '' )}
            onChange={( e ): void => this.onValueChange( e, i - 1 )}
            onBlur={( e ): void => this.onBlur( e, i - 1 )}
            maxLength={12}
          />
        </label>,
      );
    }

    return (
      <div className={classes.join( ' ' )}>
        {inputElements}
        <span>
          {stillToDistributeText}: {undistributedSharesAbsolute}
        </span>
        <br />
      </div>
    );
  }
}

export default SharesInput;
