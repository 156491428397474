export default class DateHelper {
  public static simpleDateNow(): string {
    return new Date().toLocaleDateString( 'de-DE', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    } );
  }
  public static simpleDate( date: string ): string {
    return new Date( date ).toLocaleDateString( 'de-DE', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    } );
  }
}
