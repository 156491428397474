export default class StringHelper {
  static htmlEntities( str: string ): string {
    return String( str )
      .replace( /&/g, '&amp;' )
      .replace( /</g, '&lt;' )
      .replace( />/g, '&gt;' )
      .replace( /"/g, '&quot;' );
  }

  static toSlug( str: string ): string {
    return String( str )
      .toLowerCase()
      .trim()
      .replace( /[^\w\s-]/g, '' )
      .replace( /[\s_-]+/g, '-' )
      .replace( /^-+|-+$/g, '' );
  }
}
