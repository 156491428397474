import * as React from 'react';
import { connect } from 'react-redux';

import { ContractReduxState } from '../../../../../store/reducer';
import { ContractDefinitionAttachmentReminderQuestion } from '../../../../../common/ContractDefinition';
import evaluateConditions from '../../../../../common/evaluateConditions';
import {
  ContractFormData,
  ContractVariableData,
} from '../../../../../common/ContractData';
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from '@mui/material';

export interface OwnProps {
  question: ContractDefinitionAttachmentReminderQuestion;
  name: string;
  value: string | boolean;
  showValidationErrors: boolean;
  onValueChange: ( answer: string ) => void;
}

interface StateProps {
  contractFormData: ContractFormData;
  contractVariableData: ContractVariableData;
}

type Props = StateProps & OwnProps;

class AttachmentReminderInput extends React.Component<
  Props,
  {
    showValidationErrors: boolean;
  }
> {
  constructor( props: Props ) {
    super( props );
    this.state = { showValidationErrors: false };
  }

  onValueChange( event: React.ChangeEvent<HTMLInputElement> ): void {
    this.props.onValueChange( event.target.value );
  }

  enableValidationErrors(): void {
    this.setState( { ...this.state, showValidationErrors: true } );
  }

  render(): JSX.Element {
    if (
      evaluateConditions( this.props.question.conditions, {
        ...this.props.contractFormData,
        ...this.props.contractVariableData,
      } )
    ) {
      const classes: string[] = [ 'clarius-gen-answer-block' ];
      if ( this.props.showValidationErrors || this.state.showValidationErrors ) {
        classes.push( 'clarius-gen-show-errors' );
      }

      return (
        <FormControl>
          <RadioGroup
            aria-labelledby="demo-controlled-radio-buttons-group"
            name="controlled-radio-buttons-group"
            value={this.props.value}
            onChange={( e ): void => this.onValueChange( e )}
          >
            <div>
              <FormControlLabel value="yes" control={<Radio />} label="Ja" />
            </div>
            <div>
              <FormControlLabel value="no" control={<Radio />} label="Nein" />
            </div>
          </RadioGroup>
        </FormControl>
      );
    }

    return <></>;
  }
}

const mapStateToProps = ( state: ContractReduxState ): StateProps => {
  return {
    contractFormData: state.contractFormData,
    contractVariableData: state.contractVariableData,
  };
};

export default connect( mapStateToProps )( AttachmentReminderInput );
