import * as React from 'react';
import { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AnyAction } from 'redux';

import Paginator from './Paginator/Paginator';
import ContractFormPage from './ContractFormPage/ContractFormPage';
import { ContractDefinition } from '../../common/ContractDefinition';
import { ContractDefinitionEnriched } from '../../common/ContractDefinitionEnriched';
import * as actionTypes from '../../store/actions';
import { ContractReduxState } from '../../store/reducer';
import ProgressBar from './ProgressBar/ProgressBar';
import NotificationHelper from '../../common/NotificationHelper';
import downloadContract from '../../common/downloadContract';
import { ContractFormData } from '../../common/ContractData';
import QuestionReview from './QuestionReview/QuestionReview';
import { NavigateFunction, useNavigate, useParams } from 'react-router-dom';
import {
  Button,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  Paper,
  Stack,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import PlaylistAddCheck from '@mui/icons-material/PlaylistAddCheck';
import ListAltIcon from '@mui/icons-material/ListAlt';
import SaveIcon from '@mui/icons-material/Save';
import SendIcon from '@mui/icons-material/Send';
import { ContractPartner } from '../../common/ContractPartner';
import ContactCard from '../ContactCard/ContactCard';
import Settings from '../../common/Settings';
import ApiHelper from '../../common/ApiHelper';
import LocalStorageHelper from '../../common/LocalStorageHelper';

/*componentDidUpdate(): void {
    if ( this.props.contract !== null ) {
      // Automated filling of form data
      // only executed if ?mode=dev is set in URL
      const urlParams = new URLSearchParams( window.location.search );
      const mode = urlParams.get( 'mode' );

      if ( mode === 'dev' ) {
        [
          { 'party_contact_1': 'a_gesellschaft' },
          { 'party_contact_1_gesellschaft_firmenname':
            'KP Smart Media UG (haftungsbeschränkt)' },
          { 'party_contact_1_gesellschaft_adresse':
            'Windmühlenstr. 35/ 825, 04107 Leipzig' },
          { 'party_contact_1_gesellschaft_vertreter': 'Serena Williams' },
          { 'party_contact_1_gesellschaft_funktion':
            'Praktikantin of the Universe' },
          { 'party_contact_2': 'a_einzelperson' },
          { 'party_contact_2_einzel_vorname': 'Elon' },
          { 'party_contact_2_einzel_nachname': 'Musk' },
          { 'party_contact_2_einzel_adresse': 'Red Sand Avenue 1337, 0815 ' +
            'Mars' },
          { 'q_zusammenarbeit': 'a_kooperation' },
          { 'q_vertragsGegenstand':
            '- secret buzzword business\n- it is so top secret - if you ' +
            'find out ...\n- ...' },
          { 'q_kommunikation': 'a_beides' },
          { 'q_hinweis': 'a_nein' },
          { 'q_geheimhaltung': 'a_sehrStreng' },
          { 'q_datenschutz': 'a_nein' },
          { 'q_vertragsstrafe': 'a_ja' },
          { 'q_vertragsstrafenArt': 'a_fest' },
          { 'q_vertragsstrafenSummeFest': '15.000.000' },
          { 'q_kaufleute': 'a_ja' },
          { 'q_gerichtsstand': 'a_ja' },
          { 'q_gerichtsstandOrt': 'George Town' },
        ].forEach( ( date ) => {
          const key = Object.keys( date )[ 0 ];
          const value = Object.values( date )[ 0 ];
          localStorageSet( key, value );
        } );
      }

      container.get<clariusGenSidebarController>(
        TYPES.clariusGenSidebarController
      );
      const htmlController = container.get<clariusGenHTMLController>(
        TYPES.clariusGenHTMLController,
      );
      htmlController.parseJSONQuestions( this.props.contract );

      if ( urlParams.get( 'lastPage' ) !== null ) {
        htmlController.switchPage( 9999 );
      }
    }
  }*/

function switchPage(
  navigate: NavigateFunction,
  contractDefinition: ContractDefinitionEnriched,
  toPage: number | string,
  replace = false,
  questionIdToScrollTo: string | null = null,
): void {
  if ( typeof toPage === 'string' ) {
    toPage = parseInt( toPage );
  }

  if ( isNaN( toPage ) || toPage < 1 ) {
    toPage = 1;
  } else if ( toPage >= contractDefinition.questions.length ) {
    toPage = contractDefinition.questions.length;
  }

  if ( replace ) {
    navigate( `${ toPage }`, { replace: true } );
  } else {
    navigate( `${ toPage }` );
  }

  if ( questionIdToScrollTo !== null ) {
    window.setTimeout( () => {
      const htmlElementToScrollTo: HTMLElement | null =
        document.getElementById( questionIdToScrollTo );
      if ( htmlElementToScrollTo !== null ) {
        htmlElementToScrollTo.scrollIntoView( {
          behavior: 'smooth',
          block: 'center',
        } );
      }
    }, 100 );
  }
}

function validateEverything(
  navigate: NavigateFunction,
  contractDefinition: ContractDefinitionEnriched,
  invalidQuestionData: string[],
  firstPageWithError: number,
  onShowAllValidationErrors: () => AnyAction,
): void {
  onShowAllValidationErrors();

  if ( invalidQuestionData.length > 0 ) {
    switchPage(
      navigate,
      contractDefinition,
      firstPageWithError + 1,
      false,
      invalidQuestionData[ 0 ],
    );
  } else {
    NotificationHelper.success( 'Alle Felder sind ausgefüllt' );
  }
}

function generateFormPage(
  navigate: NavigateFunction,
  currentPage: number,
  urlPage: number,
  skipDirectionForward: boolean,
  contractDataId: number,
  contractDefinition: ContractDefinitionEnriched,
  contractShortNote: string,
  contractLongNote: string,
  contractFirstParty: ContractPartner,
  contractSecondParty: ContractPartner,
  contractThirdParties: ContractPartner[],
  contractFormData: ContractFormData,
  contractFormDataSaved: ContractFormData,
  invalidQuestionData: string[],
  firstPageWithError: number,
  hiddenQuestions: string[],
  onShowAllValidationErrors: () => AnyAction,
  summaryDialogIsOpen: boolean,
  setSummaryDialogIsOpen: React.Dispatch<React.SetStateAction<boolean>>,
  onSaveContractFormData: ( contractFormData: ContractFormData ) => AnyAction,
  attachmentReminderDialogOpen: boolean,
  setAttachmentReminderDialogOpen: ( isOpen: boolean ) => void,
  attachmentReminderList: string[],
  setAttachmentReminderList: ( list: string[] ) => void,
): JSX.Element {
  let adjustedTotalPages = contractDefinition.questions.length - 1;
  let adjustedCurrentPage = currentPage;

  contractDefinition.questions.forEach( ( questionPage, index ) => {
    const oneElementNotHidden = Object.keys( questionPage ).find(
      ( key ) => hiddenQuestions.indexOf( key ) === -1,
    );

    if ( oneElementNotHidden === undefined ) {
      adjustedTotalPages--;

      if (
        currentPage !== null &&
        adjustedCurrentPage !== null &&
        index <= currentPage
      ) {
        adjustedCurrentPage--;
      }
    }
  } );

  const toolbar = (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <ProgressBar />
      </Grid>
      <Grid item xs={12}>
        <Stack
          direction={{ xs: 'column', md: 'row' }}
          justifyContent="space-between"
          spacing={2}
          sx={{ p: 2 }}
        >
          <Button
            variant="contained"
            endIcon={<PlaylistAddCheck />}
            sx={{ minWidth: '200px' }}
            onClick={(): void =>
              validateEverything(
                navigate,
                contractDefinition,
                invalidQuestionData,
                firstPageWithError,
                onShowAllValidationErrors,
              )
            }
          >
            Auf Fehler prüfen
          </Button>
          <Button
            variant="outlined"
            endIcon={<ListAltIcon />}
            onClick={(): void => setSummaryDialogIsOpen( true )}
          >
            Eingabeübersicht
          </Button>
          <Button
            variant="contained"
            color="success"
            endIcon={<SaveIcon />}
            onClick={() =>
              ApiHelper.updateContractData(
                contractDataId,
                contractFormData,
              ).then( ( result ) => {
                onSaveContractFormData( result.data.attributes.formData );
                alert( 'success' );
              } )
            }
          >
            speichern
          </Button>
          <Button
            variant="contained"
            color="success"
            endIcon={<SendIcon />}
            disabled={invalidQuestionData.length > 0}
            onClick={(): void => {
              requestPdf( contractDataId );
              const attachmentReminderList = refreshAttachmentReminderList(
                contractDefinition,
                contractFormDataSaved,
                setAttachmentReminderList,
              );
              if ( attachmentReminderList.length > 0 ) {
                setAttachmentReminderDialogOpen( true );
              }
            }}
          >
            herunterladen
          </Button>
        </Stack>
      </Grid>
    </Grid>
  );

  const paginator = (
    <Paginator
      currentPage={currentPage ?? 0}
      adjustedCurrentPage={adjustedCurrentPage ?? 0}
      totalPages={contractDefinition.questions.length - 1}
      adjustedTotalPages={adjustedTotalPages}
      pageSwitchCallback={( toPage: number | string, replace: boolean ): void =>
        switchPage( navigate, contractDefinition, toPage, replace )
      }
    />
  );

  const questionSummaryElements: JSX.Element[] = [ <hr key="initial-hr" /> ];

  contractDefinition.questions.forEach( ( questionSet, index ) => {
    Object.entries( questionSet ).forEach( ( [ key, questionDefinition ] ) => {
      if ( questionDefinition.__component !== 'question.none' ) {
        questionSummaryElements.push(
          <QuestionReview
            key={key}
            questionId={key}
            questionDefinition={questionDefinition}
            onReviewQuestion={() => {
              setSummaryDialogIsOpen( false );
              switchPage( navigate, contractDefinition, index + 1, false, key );
            }}
          />,
        );
      }
    } );
  } );

  const attachmentReminderDialog = (
    <Dialog
      open={attachmentReminderDialogOpen}
      onClose={() => {
        setAttachmentReminderDialogOpen( false );
      }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        Folgende Anlagen müssen beim Versenden des Dokuments angehängt werden:
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {attachmentReminderList}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            setAttachmentReminderDialogOpen( false );
          }}
          autoFocus
        >
          Erledigt
        </Button>
      </DialogActions>
    </Dialog>
  );

  return (
    <>
      <Dialog
        open={summaryDialogIsOpen}
        onClose={() => setSummaryDialogIsOpen( false )}
      >
        <DialogTitle>
          Eingabeübersicht
          <IconButton
            aria-label="close"
            onClick={() => setSummaryDialogIsOpen( false )}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: ( theme ) => theme.palette.grey[ 500 ],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>{questionSummaryElements}</DialogContent>
      </Dialog>
      {attachmentReminderDialog}
      <Stack spacing={2}>
        <Card sx={{ minWidth: 275 }}>
          <CardContent>
            <Typography variant="h5" component="div" sx={{ mb: 1.5 }}>
              {contractShortNote}
            </Typography>
            <Typography variant="body2" style={{ whiteSpace: 'pre-wrap' }}>
              {contractLongNote}
            </Typography>
          </CardContent>
        </Card>
        <Paper sx={{ p: 2 }}>
          <Typography variant="h5" component="div" sx={{ mb: 1.5 }}>
            Vertragspartner
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <ContactCard contractPartner={contractFirstParty} />
            </Grid>
            <Grid item xs={6}>
              <ContactCard contractPartner={contractSecondParty} />
            </Grid>
          </Grid>
        </Paper>
        <Paper sx={{ p: 2 }}>{toolbar}</Paper>
        <Paper sx={{ p: 2 }}>{paginator}</Paper>
        <Paper sx={{ p: 2 }}>
          <ContractFormPage
            key={urlPage}
            pageNum={currentPage ?? 0}
            onEmptyPage={(): void => {
              // prevent endless loop on single empty page
              if ( contractDefinition.questions.length > 1 ) {
                if ( skipDirectionForward ) {
                  switchPage(
                    navigate,
                    contractDefinition,
                    ( currentPage ?? 1 ) + 1,
                    true,
                  );
                } else {
                  switchPage(
                    navigate,
                    contractDefinition,
                    ( currentPage ?? 1 ) - 1,
                    true,
                  );
                }
              }
            }}
          />
        </Paper>
        <Paper sx={{ p: 2 }}>{paginator}</Paper>
        <Paper sx={{ p: 2 }}>{toolbar}</Paper>
      </Stack>
    </>
  );
}

function requestPdf( contractDataId: number ): void {
  downloadContract( contractDataId, 'contract' );
}

function refreshAttachmentReminderList(
  contractDefinition: ContractDefinitionEnriched,
  contractFormData: ContractFormData,
  setAttachmentReminderList: ( attachmentReminderList: string[] ) => void,
): string[] {
  const attachmentReminderList: string[] = [];

  contractDefinition.questions.forEach( ( questionSet ) => {
    Object.entries( questionSet ).forEach( ( [ name, questionDefinition ] ) => {
      const formData = contractFormData[ name ];

      if (
        questionDefinition.__component === 'question.attachment-reminder' &&
        formData !== undefined &&
        Array.isArray( formData.answers ) &&
        formData.answers !== undefined &&
        formData.answers.length > 0 &&
        formData.answers[ 0 ] === 'yes'
      ) {
        attachmentReminderList.push( questionDefinition.reminderText );
      }
    } );
  } );

  setAttachmentReminderList( attachmentReminderList );

  return attachmentReminderList;
}

export default function ContractForm(): JSX.Element {
  const navigate = useNavigate();
  const params = useParams();

  const contractDataId = Number.parseInt( params.dataId ?? '' );

  const urlPage = parseInt( params.page ?? '1' );

  const dispatch = useDispatch();
  const onContractLoad = ( contractData: {
    shortNote: string;
    longNote: string;
    first_party: ContractPartner;
    second_party: ContractPartner;
    third_parties: ContractPartner[];
    contract_definition: ContractDefinition;
    formData: ContractFormData;
  } ): AnyAction =>
    dispatch( {
      type: actionTypes.FETCH_CONTRACT,
      shortNote: contractData.shortNote,
      longNote: contractData.longNote,
      firstParty: contractData.first_party,
      secondParty: contractData.second_party,
      thirdParties: contractData.third_parties,
      contractDefinition: contractData.contract_definition,
      contractFormData: contractData.formData,
    } );
  const onShowAllValidationErrors = (): AnyAction =>
    dispatch( { type: actionTypes.SHOW_ALL_VALIDATION_ERRORS } );
  const onSaveContractFormData = (
    contractFormData: ContractFormData,
  ): AnyAction =>
    dispatch( {
      type: actionTypes.SET_CONTRACTFORMDATASAVED,
      contractFormData,
    } );

  const state = useSelector( ( state: ContractReduxState ) => {
    return state;
  } );
  const contractShortNote = state.contractShortNote;
  const contractLongNote = state.contractLongNote;
  const contractFirstParty = state.contractFirstParty;
  const contractSecondParty = state.contractSecondParty;
  const contractThirdParties = state.contractThirdParties;
  const contractDefinition = state.contractDefinition;
  const contractFormData = state.contractFormData;
  const contractFormDataSaved = state.contractFormDataSaved;
  const invalidQuestionData = state.invalidQuestionData;
  const firstPageWithError = state.firstPageWithError ?? 0;
  const hiddenQuestions = state.hiddenQuestions;

  const [ returnMessage, setReturnMessage ] = useState<string | null>( null );
  const [ currentPage, setCurrentPage ] = useState<number>( 1 );
  const [ skipDirectionForward, setSkipDirectionForward ] =
    useState<boolean>( true );
  const [ summaryDialogIsOpen, setSummaryDialogIsOpen ] = useState( false );
  const [ attachmentReminderDialogOpen, setAttachmentReminderDialogOpen ] =
    useState( false );
  const [ attachmentReminderList, setAttachmentReminderList ] = useState<
    string[]
  >( [] );

  const prevCurrentPageRef = useRef<number>( 1 );
  if ( urlPage - 1 !== currentPage ) {
    setCurrentPage( urlPage - 1 );
  }

  useEffect( () => {
    fetch( `${ Settings.apiUrl }/contract-by-data/${ contractDataId }`, {
      headers: { Authorization: 'Bearer ' + LocalStorageHelper.get( 'token' ) },
    } )
      .then( ( resolve ) => resolve.json() )
      .then(
        ( contractData: {
          shortNote: string;
          longNote: string;
          first_party: ContractPartner;
          second_party: ContractPartner;
          third_parties: ContractPartner[];
          contract_definition: ContractDefinition;
          formData: ContractFormData;
        } ) => {
          onContractLoad( contractData );
        },
      )
      .catch( ( error ) => {
        NotificationHelper.error(
          'Dokumentdaten konnten nicht geladen werden.',
          error,
        );
        setReturnMessage( 'Dokumentdaten konnten nicht geladen werden.' );
      } );
  }, [ contractDataId ] );

  useEffect( () => {
    if (
      prevCurrentPageRef !== null &&
      currentPage !== null &&
      prevCurrentPageRef.current < currentPage
    ) {
      setSkipDirectionForward( true );
    } else if (
      prevCurrentPageRef !== null &&
      currentPage !== null &&
      prevCurrentPageRef.current > currentPage
    ) {
      setSkipDirectionForward( false );
    }

    prevCurrentPageRef.current = currentPage ?? 1;
  }, [ currentPage ] );

  if ( contractDefinition !== null ) {
    return generateFormPage(
      navigate,
      currentPage,
      urlPage,
      skipDirectionForward,
      contractDataId,
      contractDefinition,
      contractShortNote,
      contractLongNote,
      contractFirstParty,
      contractSecondParty,
      contractThirdParties,
      contractFormData,
      contractFormDataSaved,
      invalidQuestionData,
      firstPageWithError,
      hiddenQuestions,
      onShowAllValidationErrors,
      summaryDialogIsOpen,
      setSummaryDialogIsOpen,
      onSaveContractFormData,
      attachmentReminderDialogOpen,
      setAttachmentReminderDialogOpen,
      attachmentReminderList,
      setAttachmentReminderList,
    );
  }

  return <div style={{ clear: 'both' }}>{returnMessage}</div>;
}
