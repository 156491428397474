import {
  ContractDefinitionQuestion,
  DateValidation,
  NumberValidation,
  TextValidation,
} from './ContractDefinition';
import { ContractFormDataPoint } from './ContractData';

class ValidationHelper {
  public static validateQuestion(
    questionDefinition: ContractDefinitionQuestion,
    dataPoint: ContractFormDataPoint,
  ): true | string[] {
    const errors = [];

    if (
      questionDefinition.__component !== 'question.checkbox' &&
      questionDefinition.__component !== 'question.multiple-choice' &&
      questionDefinition.__component !== 'question.none' &&
      dataPoint?.answers?.[ 0 ] === undefined
    ) {
      errors.push( 'no input' );
    } else if (
      typeof dataPoint?.answers?.[ 0 ] === 'string' &&
      dataPoint?.answers[ 0 ].length > 500
    ) {
      errors.push( 'too many characters' );
      console.log( 'too many characters' );
    } else if (
      typeof dataPoint?.answers?.[ 0 ] === 'string' &&
      dataPoint?.answers[ 0 ].split( /\r\n|\r|\n/ ).length > 30
    ) {
      errors.push( 'too many line breaks' );
      console.log( 'too many line breaks' );
    } else {
      const validation = questionDefinition.validation;

      if (
        typeof dataPoint?.answers?.[ 0 ] === 'string' &&
        validation?.type === 'regex'
      ) {
        if ( dataPoint?.answers[ 0 ].search( validation?.regex ) < 0 ) {
          errors.push( 'regex failed' );
        }
      } else {
        switch ( questionDefinition.__component ) {
        case 'question.single-choice':
          break;
        case 'question.text-area':
        case 'question.text-field':
          if ( validation?.type === 'text' ) {
            const result = this.checkText(
              dataPoint?.answers?.[ 0 ],
              validation,
            );

            if ( result !== true ) {
              errors.push( result );
            }
          }

          if ( validation?.type === 'date' ) {
            const result = this.checkDate(
              dataPoint?.answers?.[ 0 ],
              validation,
            );

            if ( result !== true ) {
              errors.push( result );
            }
          }

          if ( validation?.type === 'email' ) {
            if (
              typeof dataPoint?.answers?.[ 0 ] !== 'string' ||
                dataPoint?.answers[ 0 ].search(
                  // eslint-disable-next-line
                  /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/,
                ) < 0
            ) {
              errors.push( 'email invalid' );
            }
          }

          if ( validation?.type === 'postcodeCity' ) {
            if (
              typeof dataPoint?.answers?.[ 0 ] !== 'string' ||
                dataPoint?.answers[ 0 ].search(
                  // eslint-disable-next-line
                  /^([0]{1}[1-9]{1}|[1-9]{1}[0-9]{1})[0-9]{3} [A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s\-().\/]*$/,
                ) < 0
            ) {
              errors.push( 'postcode or city invalid' );
            }
          }

          if ( validation?.type === 'streetHouseNumber' ) {
            if (
              typeof dataPoint?.answers?.[ 0 ] !== 'string' ||
                dataPoint?.answers[ 0 ].search(
                  /^[A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s\-().0-9]* [0-9]+[A-Za-z]*$/,
                ) < 0
            ) {
              errors.push( 'street or house number invalid' );
            }
          }
          break;
          /*case 'currencyField':
        case 'percentageField':
          if ( validation?.type === 'number' ) {
            const result = this.checkNumber(
              dataPoint?.answers?.[ 0 ],
              validation,
            );

            if ( result !== true ) {
              errors.push( result );
            }
          }
          break;*/
        case 'question.checkbox':
          if ( validation?.type === 'checked' ) {
            if ( dataPoint?.answers?.[ 0 ] !== true ) {
              errors.push( 'not checked' );
            }
          }
          break;
        case 'question.multiple-choice':
          if ( validation?.type === 'multipleChoice' ) {
            if (
              validation?.minChecked !== undefined &&
                ( dataPoint?.answers === undefined ||
                  dataPoint?.answers.length < validation?.minChecked )
            ) {
              errors.push( 'not enough checked' );
            } else if (
              validation?.maxChecked !== undefined &&
                ( dataPoint?.answers === undefined ||
                  dataPoint?.answers?.length > validation?.maxChecked )
            ) {
              errors.push( 'too many checked' );
            }
          }
          break;
        case 'question.shares':
          const sharesSum = dataPoint.answers
            ?.map( ( answer ) => Number.parseInt( answer.toString() ) )
            .reduce( ( answer, sum = 0 ): number => sum + answer );

          if ( sharesSum !== Number.parseInt( questionDefinition.data.shares ) ) {
            errors.push( 'shares distributed incorrectly' );
          }
          break;
        case 'question.none':
        default:
          break;
        }
      }
    }

    if ( errors.length !== 0 ) {
      return errors;
    }

    return true;
  }

  private static checkDate(
    inputString: string | boolean | undefined,
    validation: DateValidation,
  ): true | string {
    if ( typeof inputString !== 'string' ) {
      return 'wrong type';
    }

    const explodedDate = inputString.split(
      /^([\d]{1,2})\.([\d]{1,2})\.([\d]{2,4})$/,
    );

    if ( explodedDate.length !== 5 ) {
      return 'invalid format';
    }

    const isoDate = `${ explodedDate[ 3 ] }-${ explodedDate[ 2 ] }-${ explodedDate[ 1 ] }`;
    const date = new Date( isoDate );

    if (
      date.toLocaleDateString( 'de-DE', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
      } ) !== inputString
    ) {
      return 'invalid date';
    }

    if (
      validation.afterDate !== undefined &&
      date.getTime() < new Date( validation.afterDate ).getTime()
    ) {
      return 'below threshold';
    }

    if (
      validation.beforeDate !== undefined &&
      date.getTime() > new Date( validation.beforeDate ).getTime()
    ) {
      return 'above threshold';
    }

    if ( validation.disallowFutureDate !== undefined && date > new Date() ) {
      return 'date in future';
    }

    if (
      validation.maxDaysInPast !== undefined &&
      new Date().getTime() - date.getTime() >
        validation.maxDaysInPast * 1000 * 3600 * 48
    ) {
      return 'too far in the past';
    }

    if (
      validation.maxDaysInFuture !== undefined &&
      date.getTime() - new Date().getTime() >
        validation.maxDaysInFuture * 1000 * 3600 * 24
    ) {
      return 'too far in the future';
    }

    return true;
  }

  private static checkNumber(
    inputString: string | boolean | undefined,
    validation: NumberValidation,
  ): true | string {
    if ( typeof inputString !== 'string' ) {
      return 'invalid type';
    }

    const cleanString = inputString.replace( '.', '' ).replace( ',', '.' );

    const splitString = cleanString.split( /^-?([\d]*)\.?([\d]*)$/ );
    if ( splitString.length !== 4 ) {
      return 'invalid format';
    }

    const number = Number.parseFloat( cleanString );

    if ( isNaN( number ) ) {
      return 'parsing error';
    }

    if (
      validation.maxPrecision !== undefined &&
      splitString[ 2 ] !== undefined &&
      splitString[ 2 ].length > validation.maxPrecision
    ) {
      return 'too many decimals';
    }

    if ( validation.minValue !== undefined && number < validation.minValue ) {
      return 'too small';
    }

    if ( validation.maxValue !== undefined && number > validation.maxValue ) {
      return 'too big';
    }

    return true;
  }

  private static checkText(
    inputString: string | boolean | undefined,
    validation: TextValidation,
  ): true | string {
    if ( typeof inputString !== 'string' ) {
      return 'invalid type';
    }

    if (
      validation.minLength !== undefined &&
      inputString.length < validation.minLength
    ) {
      return 'too short';
    }

    if (
      validation.maxLength !== undefined &&
      inputString.length > validation.maxLength
    ) {
      return 'too long';
    }

    return true;
  }
}

export default ValidationHelper;
