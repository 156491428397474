import * as React from 'react';
import { connect } from 'react-redux';

import { ContractReduxState } from '../../../../store/reducer';
import { ContractDefinition } from '../../../../common/ContractDefinition';
import { ContractDefinitionQuestionEnriched } from '../../../../common/ContractDefinitionEnriched';
import ExplanationIcon from './InputElements/ExplanationIcon';
import QuestionInput from './QuestionInput';
import { Typography } from '@mui/material';

export interface OwnProps {
  questionDefinition: ContractDefinitionQuestionEnriched;
  questionId: string;
}

interface StateProps {
  contractDefinition: ContractDefinition;
  invalidQuestionData: string[];
}

type Props = StateProps & OwnProps;

class QuestionInputWrapper extends React.Component<Props, {}> {
  render(): JSX.Element {
    let explanationIcon: JSX.Element = <></>;
    if (
      this.props.questionDefinition.explanation !== undefined &&
      this.props.questionDefinition.explanation !== '' &&
      this.props.questionDefinition.explanation !== '\n' &&
      ( !Array.isArray( this.props.questionDefinition.explanation ) ||
        this.props.questionDefinition.explanation.length > 0 )
    ) {
      explanationIcon = (
        <ExplanationIcon
          explanationTitle={this.props.questionDefinition.questionText}
          explanation={this.props.questionDefinition.explanation as string}
        />
      );
    }

    return (
      <>
        <Typography variant="h5">
          {this.props.questionDefinition.questionText} {explanationIcon}
        </Typography>
        <QuestionInput
          questionId={this.props.questionId}
          questionDefinition={this.props.questionDefinition}
          isInvalid={this.props.invalidQuestionData.includes(
            this.props.questionId,
          )}
        />
      </>
    );
  }
}

const mapStateToProps = ( state: ContractReduxState ): StateProps => {
  return {
    contractDefinition: state.contractDefinition,
    invalidQuestionData: state.invalidQuestionData,
  };
};

export default connect( mapStateToProps )( QuestionInputWrapper );
