export const FETCH_CONTRACT = 'FETCH_CONTRACT';
export const SET_CONTRACTFORMDATASAVED = 'SET_CONTRACTFORMDATASAVED';
export const SET_ANSWERS = 'SET_ANSWERS';
export const ADD_ANSWER = 'ADD_ANSWER';
export const REMOVE_ANSWER = 'REMOVE_ANSWER';
export const UNSET_ANSWERS = 'UNSET_ANSWERS';
export const UNSET_ALL_ANSWERS = 'UNSET_ALL_ANSWERS';
export const SET_INFO_DRAWER_CONTENT = 'SET_INFO_DRAWER_CONTENT';
export const SET_INFO_DRAWER_STATE = 'SET_INFO_DRAWER_STATE';
export const SHOW_ALL_VALIDATION_ERRORS = 'SHOW_ALL_VALIDATION_ERRORS';
