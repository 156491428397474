import React from 'react';

import './theme.css';

import { createTheme, ThemeProvider, useTheme } from '@mui/material';
import { deDE } from '@mui/material/locale';

export const ColorModeContext = React.createContext( {
  toggleColorMode: () => {
    //
  },
} );

export function Theme( { children }: { children: JSX.Element } ): JSX.Element {
  const [ mode, setMode ] = React.useState<'light' | 'dark'>( 'light' );
  const colorMode = React.useMemo(
    () => ( {
      toggleColorMode: () => {
        setMode( ( prevMode ) => ( prevMode === 'light' ? 'dark' : 'light' ) );
      },
    } ),
    [],
  );

  const theme = React.useMemo(
    () =>
      createTheme(
        {
          typography: {
            fontFamily: 'Montserrat',
          },
          palette: {
            mode,
            primary: {
              main: '#902c7c',
            },
          },
        },
        deDE,
      ),
    [ mode ],
  );

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </ColorModeContext.Provider>
  );
}
