import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Box,
  Drawer,
  Grid,
  IconButton,
  Paper,
  Stack,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { SET_INFO_DRAWER_STATE } from '../../store/actions';

export default function InfoDrawer(): JSX.Element {
  const dispatch = useDispatch();
  const closeInfoDrawer = (): void => {
    dispatch( { type: SET_INFO_DRAWER_STATE, isOpen: false } );
  };

  const infoDrawerIsOpen = useSelector(
    ( state: { infoDrawerState: { isOpen: boolean } } ) => state.infoDrawerState,
  ).isOpen;
  const infoDrawerContent = useSelector(
    ( state: { infoDrawerContent: { headline: string; text: string } } ) =>
      state.infoDrawerContent,
  );

  return (
    <Drawer variant="persistent" anchor="bottom" open={infoDrawerIsOpen}>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="flex-start"
        sx={{ p: 2 }}
      >
        <Grid item xs={1} />
        <Grid item xs={10}>
          <Stack spacing={2}>
            <Typography variant="h6">{infoDrawerContent.headline}</Typography>
            <Box sx={{ maxHeight: '25vh', overflowY: 'auto' }}>
              {infoDrawerContent.text.split( '\n' ).map( ( line, index ) => {
                return <p key={index}>{line}</p>;
              } )}
            </Box>
          </Stack>
        </Grid>
        <Grid item xs={1}>
          <IconButton
            aria-label="close info drawer"
            onClick={() => closeInfoDrawer()}
          >
            <CloseIcon />
          </IconButton>
        </Grid>
      </Grid>
    </Drawer>
  );
}
