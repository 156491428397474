import React from 'react';
import {
  Avatar,
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  Link,
  TextField,
  Typography,
} from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { useLocation, useNavigate } from 'react-router-dom';
import { AuthContext } from '../../auth';
import axios from 'axios';
import Settings from '../../common/Settings';
import LocalStorageHelper from '../../common/LocalStorageHelper';

export default function SignIn(): JSX.Element {
  type LocationProps = {
    state: {
      from: Location;
    };
  };

  const navigate = useNavigate();
  const location = useLocation() as unknown as LocationProps;
  const auth = React.useContext( AuthContext );

  const from = location.state?.from?.pathname || '/';

  const handleSubmit = ( event: React.FormEvent<HTMLFormElement> ) => {
    event.preventDefault();

    const data = new FormData( event.currentTarget );
    auth.signin( data.get( 'email' ) as string, () => {
      axios
        .post( `${ Settings.apiUrl }/auth/local`, {
          identifier: data.get( 'email' ) as string,
          password: data.get( 'password' ) as string,
        } )
        .then( ( response ) => {
          if ( response.data.jwt ) {
            LocalStorageHelper.set( 'token', response.data.jwt );
            navigate( from, { replace: true } );
          }

          if ( response.data.error ) {
            alert( response.data.error );
          }
        } );
    } );
  };

  return (
    <Box
      sx={{
        marginTop: 8,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <img
        src="./ClariusLegal_Logo.svg"
        width="650px"
        style={{ marginBottom: 10 }}
      />
      {/*<Typography variant="body2">powered by</Typography>
      <Typography variant="h4" sx={{ mb: 5 }}>
        Clarius Contracts
      </Typography>*/}
      <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
        <LockOutlinedIcon />
      </Avatar>
      <Typography component="h1" variant="h5">
        Sign in
      </Typography>
      <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
        <TextField
          margin="normal"
          required
          fullWidth
          id="email"
          label="Email Address"
          name="email"
          autoComplete="email"
          autoFocus
        />
        <TextField
          margin="normal"
          required
          fullWidth
          name="password"
          label="Password"
          type="password"
          id="password"
          autoComplete="current-password"
        />
        {/*<FormControlLabel
          control={<Checkbox value="remember" color="primary" />}
          label="Remember me"
        />*/}
        <Button
          type="submit"
          fullWidth
          variant="contained"
          sx={{ mt: 3, mb: 2 }}
        >
          Sign In
        </Button>
        {/* <Grid container>
          <Grid item xs>
            <Link href="#" variant="body2">
              Forgot password?
            </Link>
          </Grid>
          <Grid item>
            <Link href="#" variant="body2">
              {'Don\'t have an account? Sign Up'}
            </Link>
          </Grid>
        </Grid> */}
      </Box>
    </Box>
  );
}
