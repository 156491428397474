import downloadFile from './downloadFile';
import LocalStorageHelper from './LocalStorageHelper';
import NotificationHelper from './NotificationHelper';
import Settings from './Settings';

export default function downloadContract(
  contractDataId: number,
  filename: string,
): void {
  fetch( `${ Settings.apiUrl }/generate-by-data/${ contractDataId }`, {
    method: 'GET',
    mode: 'cors',
    cache: 'no-cache',
    credentials: 'same-origin',
    headers: {
      Authorization: 'Bearer ' + LocalStorageHelper.get( 'token' ),
      'Content-Type': 'application/json',
    },
    redirect: 'follow',
    referrerPolicy: 'no-referrer',
  } )
    .then( ( resolve ) => {
      if ( !resolve.ok ) {
        throw new Error( 'Dokument konnte nicht generiert werden.' );
      }

      return resolve.blob();
    } )
    .then( ( pdfData: Blob ) => {
      downloadFile( pdfData, `${ filename }.docx` );
    } )
    .catch( ( error ) => {
      NotificationHelper.error(
        'Ein Fehler ist aufgetreten. Bitte prüfe alle Angaben und versuche es noch einmal. ' +
          'Wende dich bitte über das Kontaktformular an uns, wenn das Problem weiterhin besteht.',
        error,
      );
    } );
}
