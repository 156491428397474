import { ContractDefinitionCondition } from './ContractDefinition';
import { ContractData } from './ContractData';
import StringHelper from './StringHelper';

/**
 * The input has to be an array of objects.
 * All keys of one object will be used to identify input elements by their
 * names. Those input elements will then be compared to the values of the
 * object. If an object contains only one false comparison, it will count as
 * false (like an AND comparison).
 * When one object is compared completely true, this function will also
 * return true (like an OR comparison).
 *
 * So [{true, true}, {true, true}] will return true
 * and [{true, true}, {false, true}] will still return true.
 * But [{true, false}, {false, true}] will return false.
 * Also [{false, false}, {false, false}] will still return false.
 *
 * @param { array } conditions
 */
const evaluateConditions = (
  conditions: ContractDefinitionCondition[],
  contractData: ContractData,
): boolean => {
  if (
    conditions === undefined ||
    conditions === null ||
    conditions.length === 0
  ) {
    return true;
  }

  for ( const index in conditions ) {
    // the result of the loop passing is true until one element compares
    // false in the following loop
    let conditionResult = true;

    // every element has to have a certain value (AND) for this
    // condition to be true
    for ( const [ dataId, expectedValue ] of Object.entries( conditions[ index ] ) ) {
      debugger;
      const answers = contractData[ StringHelper.toSlug( dataId ) ]?.answers;
      const elementComparisonResult =
        contractData !== null &&
        answers !== undefined &&
        answers !== null &&
        answers.includes( expectedValue );

      if ( !elementComparisonResult ) {
        // if it is false now, it will stay false, so we can stop here
        conditionResult = false;
        break;
      }
    }

    // this is an OR condition, so if it is true now, it will be true
    if ( conditionResult ) {
      return true;
    }
  }

  // nothing in the OR condition was true, so it false
  return false;
};

export default evaluateConditions;
