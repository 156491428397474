import React from 'react';

interface AuthContextType {
  user: string | null;
  signin: ( user: string, callback: VoidFunction ) => void;
  signout: ( callback: VoidFunction ) => void;
}

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const AuthContext = React.createContext<AuthContextType>( null! );

const fakeAuthProvider = {
  isAuthenticated: false,
  signin( callback: VoidFunction ): void {
    fakeAuthProvider.isAuthenticated = true;
    setTimeout( callback, 100 ); // fake async
  },
  signout( callback: VoidFunction ): void {
    fakeAuthProvider.isAuthenticated = false;
    setTimeout( callback, 100 );
  },
};

export { fakeAuthProvider, AuthContext };
