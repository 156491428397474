import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch, AnyAction } from 'redux';

import { ContractReduxState } from '../../../store/reducer';
import { ContractDefinition } from '../../../common/ContractDefinition';
import {
  ContractFormData,
  ContractVariableData,
} from '../../../common/ContractData';
import ProgressHelper from '../../../common/ProgressHelper';
import LocalStorageHelper from '../../../common/LocalStorageHelper';
import * as actionTypes from '../../../store/actions';
import { Button, LinearProgress, Stack } from '@mui/material';

interface StateProps {
  contractDefinition: ContractDefinition;
  contractFormData: ContractFormData;
  contractVariableData: ContractVariableData;
  invalidQuestionData: string[];
}

interface DispatchProps {
  clearFormData: () => void;
}

type Props = StateProps & DispatchProps;

class ProgressBar extends React.Component<Props, {}, {}> {
  render(): JSX.Element {
    const percentage = ProgressHelper.calculateProgressPercentage(
      this.props.contractDefinition,
      this.props.contractFormData,
      this.props.contractVariableData,
      this.props.invalidQuestionData,
    );

    if ( this.props.contractDefinition.id ) {
      LocalStorageHelper.set(
        `${ this.props.contractDefinition.id }_percentage`,
        percentage,
      );
    }

    return (
      <>
        <Stack
          direction="row"
          justifyContent="left"
          alignItems="center"
          spacing={2}
        >
          {Math.round( ( percentage ?? 0 ) * 100 )} % ausgefüllt
          <Button
            variant="text"
            onClick={(): void => {
              if (
                window.confirm(
                  'Bist du dir sicher, dass du alle bereits ausgefüllten Felder ' +
                    'zurücksetzen möchtest? Dein Formular ist dann wieder leer ' +
                    'und zum Ausfüllen bereit.',
                )
              ) {
                this.props.clearFormData();
              }
            }}
          >
            Alles zurücksetzen
          </Button>
        </Stack>
        <LinearProgress variant="determinate" value={( percentage ?? 0 ) * 100} />
      </>
    );
  }
}

const mapStateToProps = ( state: ContractReduxState ): StateProps => {
  return {
    contractDefinition: state.contractDefinition,
    contractFormData: state.contractFormData,
    contractVariableData: state.contractVariableData,
    invalidQuestionData: state.invalidQuestionData,
  };
};

const mapDispatchToProps = ( dispatch: Dispatch ): DispatchProps => {
  return {
    clearFormData: (): AnyAction =>
      dispatch( {
        type: actionTypes.UNSET_ALL_ANSWERS,
      } ),
  };
};

export default connect( mapStateToProps, mapDispatchToProps )( ProgressBar );
