import React from 'react';
import {
  Card,
  CardActions,
  CardContent,
  IconButton,
  Typography,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DownloadIcon from '@mui/icons-material/Download';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import DoneIcon from '@mui/icons-material/Done';
import DeleteIcon from '@mui/icons-material/Delete';

import { useNavigate } from 'react-router-dom';
import downloadContract from '../../common/downloadContract';
import LocalStorageHelper from '../../common/LocalStorageHelper';
import { ContractPartner } from '../../common/ContractPartner';
import { create } from 'domain';
import DateHelper from '../../common/DateHelper';

export default function OpenContractCard( props: {
  contractDataId: number;
  contractPartner: ContractPartner;
  title: string;
  createdAt: string;
  updatedAt: string;
  handleClose: () => void;
  handleDelete: () => void;
} ): JSX.Element {
  const {
    contractDataId,
    contractPartner,
    title,
    createdAt,
    updatedAt,
    handleClose,
    handleDelete,
  } = props;

  const navigate = useNavigate();

  function handleEdit() {
    navigate( `/contract/${ contractDataId }` );
  }

  function handleDownload() {
    //downloadContract( contractId, contractFormData, 'contract' );
  }

  let details: string | JSX.Element = '';
  if ( contractPartner.data[ 0 ].__component === 'contact.natural-person' ) {
    details = (
      <>
        {contractPartner.data[ 0 ].firstname} {contractPartner.data[ 0 ].lastname}
        <br />
        {contractPartner.data[ 0 ].streetHouseNumber}
        <br />
        {contractPartner.data[ 0 ].zipCity}
        <br />
        &nbsp;
        <br />
        &nbsp;
      </>
    );
  } else if ( contractPartner.data[ 0 ].__component === 'contact.legal-entity' ) {
    details = (
      <>
        {contractPartner.data[ 0 ].company}
        <br />
        {contractPartner.data[ 0 ].streetHouseNumber}
        <br />
        {contractPartner.data[ 0 ].zipCity}
        <br />
        {contractPartner.data[ 0 ].representative}
        <br />
        {contractPartner.data[ 0 ].functionRepresentative}
      </>
    );
  }

  return (
    <Card>
      <CardContent>
        <Typography
          variant="h6"
          component="div"
          color="#e65100"
          overflow="hidden"
          textOverflow="ellipsis"
        >
          {title}
        </Typography>
        <hr />
        <Typography
          variant="body2"
          sx={{
            textOverflow: 'ellipsis',
            overflow: 'hidden',
          }}
        >
          {details}
        </Typography>
        <br />
        <Typography variant="subtitle2">
          <AddIcon fontSize="inherit" /> {DateHelper.simpleDate( createdAt )}
          <br />
          <EditIcon fontSize="inherit" /> {DateHelper.simpleDate( updatedAt )}
        </Typography>
      </CardContent>
      <CardActions>
        <IconButton onClick={handleEdit}>
          <EditIcon />
        </IconButton>
        <IconButton onClick={handleDownload}>
          <DownloadIcon />
        </IconButton>
        <IconButton onClick={handleClose}>
          <DoneIcon />
        </IconButton>
        <IconButton onClick={handleDelete}>
          <DeleteIcon />
        </IconButton>
      </CardActions>
    </Card>
  );
}
