import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch, AnyAction } from 'redux';

import * as actionTypes from '../../../../../store/actions';
import Info from '@mui/icons-material/Info';
import { Link } from '@mui/material';

export interface OwnProps {
  explanationTitle: string;
  explanation: string;
}

interface DispatchProps {
  onExplanationBoxSet: ( headline: string, text: string ) => void;
}

type Props = OwnProps & DispatchProps;

class ExplanationIcon extends React.Component<Props> {
  handleClick( event: React.MouseEvent<HTMLSpanElement, MouseEvent> ): void {
    event.preventDefault();

    this.props.onExplanationBoxSet(
      this.props.explanationTitle,
      this.props.explanation,
    );

    //controller.expandExplanationBox();
  }

  render(): JSX.Element {
    return (
      <Link href="#" onClick={( e ): void => this.handleClick( e )}>
        <Info />
      </Link>
    );
  }
}

const mapDispatchToProps = ( dispatch: Dispatch ): DispatchProps => {
  return {
    onExplanationBoxSet: ( headline: string, text: string ): void => {
      dispatch( {
        type: actionTypes.SET_INFO_DRAWER_CONTENT,
        headline: headline,
        text: text,
      } );
      dispatch( {
        type: actionTypes.SET_INFO_DRAWER_STATE,
        isOpen: true,
      } );
    },
  };
};

export default connect( null, mapDispatchToProps )( ExplanationIcon );
