import { TextField } from '@mui/material';
import * as React from 'react';

import { ContractDefinitionTextAreaQuestion } from '../../../../../common/ContractDefinition';

export interface OwnProps {
  question: ContractDefinitionTextAreaQuestion;
  value: string;
  showValidationErrors: boolean;
  isInvalid: boolean;
  onValueChange: ( answer: string ) => void;
}

class TextAreaInput extends React.Component<
  OwnProps,
  {
    timeoutId: number | null;
    value: string;
    showValidationErrors: boolean;
  }
> {
  constructor( props: OwnProps ) {
    super( props );
    this.state = {
      timeoutId: null,
      value: this.props.value,
      showValidationErrors: false,
    };
  }

  onValueChange(
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ): void {
    if ( this.state.timeoutId !== null ) {
      clearTimeout( this.state.timeoutId );
    }
    const value = event.target.value;

    const timeoutId = window.setTimeout( () => {
      this.props.onValueChange( this.state.value );
    }, 2000 );

    this.setState( {
      ...this.state,
      timeoutId: timeoutId,
      value: value,
    } );
  }

  onBlur(
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ): void {
    const value = event.target.value.trim();
    if ( this.state.timeoutId !== null ) {
      clearTimeout( this.state.timeoutId );
    }
    this.setState( { ...this.state, value: value } );
    this.props.onValueChange( value );
    this.enableValidationErrors();
  }

  enableValidationErrors(): void {
    this.setState( { ...this.state, showValidationErrors: true } );
  }

  render(): JSX.Element {
    let placeholder = '';
    if ( Array.isArray( this.props.question.placeholder ) ) {
      const questionData = this.props.question.placeholder;
      placeholder = questionData.join( '\n' );
    } else {
      placeholder = ( this.props.question.placeholder ?? '' ) as string;
    }

    return (
      <TextField
        multiline
        minRows={4}
        placeholder={placeholder}
        value={this.state.value}
        onChange={( e ): void => this.onValueChange( e )}
        onBlur={( e ): void => this.onBlur( e )}
        sx={{ minWidth: '100%' }}
        error={
          ( this.props.showValidationErrors ||
            this.state.showValidationErrors ) &&
          this.props.isInvalid
        }
      />
    );
  }
}

export default TextAreaInput;
