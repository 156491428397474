import React, { useEffect, useState } from 'react';
import { Card, CardContent, Typography } from '@mui/material';
import { ContractPartner } from '../../common/ContractPartner';

export default function ContactCard( props: {
  contractPartner: ContractPartner;
} ): JSX.Element {
  let bottomPart: JSX.Element = <></>;

  if ( props.contractPartner.data[ 0 ].__component === 'contact.legal-entity' ) {
    let functionRepresentative = '';

    if ( props.contractPartner.data[ 0 ].functionRepresentative ) {
      functionRepresentative = `(${ props.contractPartner.data[ 0 ].functionRepresentative })`;
    }

    bottomPart = (
      <>
        <Typography variant="h5" component="div">
          {props.contractPartner.data[ 0 ].company}
        </Typography>
        <Typography sx={{ mb: 1.5 }} color="text.secondary">
          {props.contractPartner.data[ 0 ].representative}{' '}
          {functionRepresentative}
        </Typography>
        <Typography variant="body2">
          {props.contractPartner.data[ 0 ].streetHouseNumber}
          <br />
          {props.contractPartner.data[ 0 ].zipCity}
        </Typography>
      </>
    );
  } else if (
    props.contractPartner.data[ 0 ].__component === 'contact.natural-person'
  ) {
    bottomPart = (
      <>
        <Typography variant="h5" component="div">
          {props.contractPartner.alias}
        </Typography>
        <Typography sx={{ mb: 1.5 }} color="text.secondary">
          adjective
        </Typography>
        <Typography variant="body2">
          well meaning and kindly.
          <br />
          {'"a benevolent smile"'}
        </Typography>
      </>
    );
  }

  return (
    <Card sx={{ minWidth: 275 }}>
      <CardContent>
        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
          {props.contractPartner.alias}
        </Typography>
        {bottomPart}
      </CardContent>
    </Card>
  );
}
