import * as React from 'react';

import { ContractDefinitionCheckboxQuestion } from '../../../../../common/ContractDefinition';

export interface OwnProps {
  question: ContractDefinitionCheckboxQuestion;
  value: string | boolean;
  showValidationErrors: boolean;
  onValueChange: ( answer: boolean ) => void;
}

class CheckboxInput extends React.Component<
  OwnProps,
  {
    showValidationErrors: boolean;
  }
> {
  constructor( props: OwnProps ) {
    super( props );
    this.state = { showValidationErrors: false };
  }

  onValueChange( event: React.ChangeEvent<HTMLInputElement> ): void {
    this.props.onValueChange( event.target.checked );
  }

  enableValidationErrors(): void {
    this.setState( { ...this.state, showValidationErrors: true } );
  }

  render(): JSX.Element {
    const answerDefinition = this.props.question.data;

    let longAnswer: JSX.Element = <></>;
    if (
      answerDefinition.longAnswer !== undefined &&
      answerDefinition.longAnswer !== null
    ) {
      longAnswer = (
        <span className="clarius-long-answer">
          {answerDefinition.longAnswer}
        </span>
      );
    }

    let checked = false;
    if ( this.props.value === true ) {
      checked = true;
    }

    const classes: string[] = [ 'clarius-gen-answer-block' ];
    if ( this.props.showValidationErrors || this.state.showValidationErrors ) {
      classes.push( 'clarius-gen-show-errors' );
    }

    return (
      <div className={classes.join( ' ' )}>
        <label className="clarius-label-checkbox">
          <input
            type="checkbox"
            onChange={( e ): void => this.onValueChange( e )}
            onBlur={(): void => this.enableValidationErrors()}
            checked={checked}
          />
          <div className="control_indicator" />
          <span className="clarius-short-answer">
            {answerDefinition.shortAnswer}
          </span>
          {longAnswer}
        </label>
        <br />
      </div>
    );
  }
}

export default CheckboxInput;
