import qs from 'qs';
import { ContractFormData } from './ContractData';
import { ContractDefinition } from './ContractDefinition';
import { ContractPartner } from './ContractPartner';
import LocalStorageHelper from './LocalStorageHelper';
import Settings from './Settings';

interface QueryParams {
  fields?: string | string[];
  populate?: {
    [key: string]: '*' | QueryParams;
  };
}

export interface ContractData {
  id: number;
  attributes: {
    closed: boolean;
    shortNote: string;
    longNote: string;
    createdAt: string;
    updatedAt: string;
    first_party: { data: { attributes: ContractPartner } };
    second_party: { data: { attributes: ContractPartner } };
    third_parties: { data: { attributes: ContractPartner[] } };
    contract_definition: {
      data: { attributes: { id: number; title: string; titlePlural: string } };
    };
    formData: ContractFormData;
  };
}

export default class ApiHelper {
  public static checkToken( logoutCallback: () => void ): void {
    this.apiGetCall( '/users/me' )
      .then( ( response ) => {
        if ( ( response as { error?: { status: number } } ).error !== undefined ) {
          logoutCallback();
        }
      } )
      .catch( ( error ) => {
        console.error( error );
      } );
  }

  public static listContractDefinitions(): Promise<{
    data: ContractDefinition[];
  }> {
    return this.apiGetCall( '/contract-definitions', {
      fields: [ 'title', 'shortDescription' ],
    } ) as Promise<{ data: ContractDefinition[] }>;
  }

  public static listContractDatas(): Promise<{ data: ContractData[] }> {
    return this.apiGetCall( '/contract-datas', {
      fields: [ 'shortNote', 'longNote', 'closed', 'createdAt', 'updatedAt' ],
      populate: {
        contract_definition: { fields: [ 'title', 'titlePlural' ] },
        first_party: {
          fields: [ 'alias' ],
          populate: { data: { fields: '*' } },
        },
        second_party: {
          fields: [ 'alias' ],
          populate: { data: { fields: '*' } },
        },
        third_parties: {
          fields: 'alias',
          populate: { data: { fields: '*' } },
        },
      },
    } ) as Promise<{ data: ContractData[] }>;
  }

  public static createContractData(
    contractDefinitionId: number,
    firstPartyId: number,
    secondPartyId: number,
  ): Promise<{ data: ContractData }> {
    return this.apiPostCall(
      '/contract-datas',
      JSON.stringify( {
        data: {
          shortNote: 'Test',
          longNote: 'Testvertrag',
          formData: {},
          contract_definition: contractDefinitionId,
          first_party: firstPartyId,
          second_party: secondPartyId,
        },
      } ),
    ) as Promise<{
      data: ContractData;
    }>;
  }

  public static updateContractData(
    contractDefinitionId: number,
    contractFormData: ContractFormData,
  ): Promise<{ data: ContractData }> {
    return this.apiUpdateCall(
      '/contract-datas',
      contractDefinitionId,
      JSON.stringify( { data: { formData: contractFormData } } ),
    ) as Promise<{
      data: ContractData;
    }>;
  }

  public static deleteContractData(
    contractDefinitionId: number,
  ): Promise<{ data: ContractData }> {
    return this.apiDeleteCall(
      '/contract-datas',
      contractDefinitionId,
    ) as Promise<{
      data: ContractData;
    }>;
  }

  public static closeContractData(
    contractDefinitionId: number,
  ): Promise<{ data: ContractData }> {
    return this.apiUpdateCall(
      '/contract-datas',
      contractDefinitionId,
      JSON.stringify( {
        data: {
          closed: true,
        },
      } ),
    ) as Promise<{
      data: ContractData;
    }>;
  }

  private static apiCall(
    url: string,
    method: string,
    additionalHeaders?: { 'Content-Type': string },
    body?: string,
  ) {
    return new Promise( ( resolve, reject ) => {
      fetch( url, {
        method,
        headers: {
          Authorization: 'Bearer ' + LocalStorageHelper.get( 'token' ),
          ...additionalHeaders,
        },
        body,
      } )
        .then( ( response ) => {
          response
            .json()
            .then( ( json ) => {
              resolve( json );
            } )
            .catch( ( error ) => {
              reject( error );
            } );
        } )
        .catch( ( error ) => {
          reject( error );
        } );
    } );
  }

  private static apiGetCall( apiPath: string, queryParams?: QueryParams ) {
    const query = qs.stringify( queryParams, { encodeValuesOnly: true } );

    return this.apiCall( `${ Settings.apiUrl }${ apiPath }?${ query }`, 'GET' );
  }

  private static apiPostCall( apiPath: string, body?: string ) {
    return this.apiCall(
      `${ Settings.apiUrl }${ apiPath }`,
      'POST',
      { 'Content-Type': 'application/json' },
      body,
    );
  }

  private static apiUpdateCall( apiPath: string, id: number, body?: string ) {
    return this.apiCall(
      `${ Settings.apiUrl }${ apiPath }/${ id }`,
      'PUT',
      { 'Content-Type': 'application/json' },
      body,
    );
  }

  private static apiDeleteCall( apiPath: string, id: number ) {
    return this.apiCall( `${ Settings.apiUrl }${ apiPath }/${ id }`, 'DELETE' );
  }
}
