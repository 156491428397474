import { Collapse, Fade, Grow, Slide, Stack } from '@mui/material';
import * as React from 'react';
import { connect } from 'react-redux';

import { ContractDefinitionEnriched } from '../../../common/ContractDefinitionEnriched';
import { ContractReduxState } from '../../../store/reducer';
import Question from './Question/Question';

export interface OwnProps {
  pageNum: number;
  onEmptyPage: () => void;
}

interface StateProps {
  contractDefinition: ContractDefinitionEnriched;
  hiddenQuestions: string[];
}

type Props = StateProps & OwnProps;

class ContractFormPage extends React.Component<Props, {}> {
  render(): JSX.Element {
    const questions =
      this.props.contractDefinition.questions[ this.props.pageNum ];
    if ( questions === undefined ) {
      return <></>;
    }

    const questionElements: JSX.Element[] = [];
    Object.entries( questions ).forEach( ( [ key, questionDefinition ] ) => {
      const showQuestion = this.props.hiddenQuestions.indexOf( key ) === -1;

      questionElements.push(
        <Collapse key={key} in={showQuestion} mountOnEnter unmountOnExit>
          <Question questionId={key} questionDefinition={questionDefinition} />
        </Collapse>,
      );
    } );

    return <Stack spacing={4}>{questionElements}</Stack>;
  }

  componentDidUpdate(): void {
    const questions =
      this.props.contractDefinition.questions[ this.props.pageNum ];
    if ( questions === undefined ) {
      return;
    }
    const oneElementNotHidden = Object.keys( questions ).find(
      ( key ) => this.props.hiddenQuestions.indexOf( key ) === -1,
    );

    if ( oneElementNotHidden === undefined ) {
      this.props.onEmptyPage();
    }
  }
}

const mapStateToProps = ( state: ContractReduxState ): StateProps => {
  return {
    contractDefinition: state.contractDefinition,
    hiddenQuestions: state.hiddenQuestions,
  };
};

export default connect( mapStateToProps )( ContractFormPage );
