import evaluateConditions from './evaluateConditions';
import { ContractDefinition } from './ContractDefinition';
import { ContractFormData, ContractVariableData } from './ContractData';

class ProgressHelper {
  public static calculateProgress(
    contractDefinition: ContractDefinition,
    contractFormData: ContractFormData,
    contractVariableData: ContractVariableData,
    invalidQuestionData: string[],
  ): [number, number] {
    let questionCount = 0;
    let answeredQuestionCount = 0;

    contractDefinition.questions.forEach( ( questionSet ) => {
      Object.entries( questionSet ).forEach( ( [ key, value ] ) => {
        if (
          value.__component !== 'question.none' &&
          value.__component !== 'question.multiple-choice' &&
          evaluateConditions( value.conditions, {
            ...contractFormData,
            ...contractVariableData,
          } )
        ) {
          questionCount++;

          if (
            invalidQuestionData.indexOf( key ) === -1 &&
            contractFormData[ key ] !== undefined &&
            contractFormData[ key ] !== null &&
            contractFormData[ key ].answers !== undefined &&
            contractFormData[ key ].answers !== null &&
            contractFormData[ key ].answers?.[ 0 ] !== ''
          ) {
            answeredQuestionCount++;
          }
        }
      } );
    } );

    return [ questionCount, answeredQuestionCount ];
  }

  public static calculateProgressPercentage(
    contractDefinition: ContractDefinition,
    contractFormData: ContractFormData,
    contractVariableData: ContractVariableData,
    invalidQuestionData: string[],
  ): number | null {
    const [ questionCount, answeredQuestionCount ] = this.calculateProgress(
      contractDefinition,
      contractFormData,
      contractVariableData,
      invalidQuestionData,
    );

    let percentage = null;
    if ( questionCount > 0 ) {
      percentage = answeredQuestionCount / questionCount;
    }

    return percentage;
  }
}

export default ProgressHelper;
